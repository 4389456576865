<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >Category Title</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Firstname-input"
                        v-model.trim="$v.form.title.$model"
                        placeholder="Enter Category Name"
                      />
                      <div v-if="$v.form.title.$error">
                        <div class="error" v-if="$v.form.title.$error">
                          Title is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.title.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Category Slug</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="formrow-Lastname-input"
                        v-model.trim="$v.form.slug.$model"
                        placeholder="Enter Category Slug"
                      />
                      <div v-if="$v.form.slug.$error">
                        <div class="error" v-if="$v.form.slug.$error">
                          Slug is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.slug.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                      <!-- <div class="error" v-if="$v.form.slug.$error">
                        Slug is required
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <SubmitButton
                    :processing="processing"
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                    text="Add Category"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required, alpha } from "vuelidate/lib/validators";
export default {
  name: "add-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Category",
      processing: false,
      form: {
        title: null,
        slug: null,
      },
    };
  },
  validations: {
    form: {
      title: {
        required,
        // alpha,
      },
      slug: {
        required,
        // alpha,
      },
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.processing = true;
        console.log(this.form);
        this.$axios
          .post("categories", this.form)
          .then((response) => {
            this.$router.push({ path: "/category/listing" });
            this.triggerSwal(response.data.message, "success");
            // console.log(response.data);
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          });
      }
    },
  },
};
</script>